import { Client } from "./client";
import fetch from "cross-fetch";
import cache from "./cache";

const api = new Client(process.env.NEXT_PUBLIC_API_URL, {
  fetch: (params) => {
    console.log("API: ", params);
    return fetch(params);
  },
});

export const cacheAsyncFn = async <T extends (...args: any) => ReturnType<T>>(
  fn: T,
  ...params
) => {
  const key = `${params.concat("/")}${fn.name}`;
  if (cache.has(key)) {
    console.log("hit", key);
    return cache.get(key) as ReturnType<typeof fn>;
  } else {
    const response: ReturnType<typeof fn> = await fn.bind(api)(...params);
    cache.set(key, response);
    return response;
  }
};

export default api;
