import { configureStore, createReducer, createAction } from "@reduxjs/toolkit";
import api from "api/api";

export interface SystemState {
  light: boolean;
  bootstrap: boolean;
  language: string;
}

export const reHydrateBrowserStore = async () => {
  let localStorage = false as any;
  if (typeof window === "undefined") {
    return {};
  }

  localStorage = window && window.localStorage;

  let settings = null as any;
  if (localStorage.getItem("applicationState") !== null) {
    settings = JSON.parse(localStorage.getItem("applicationState"));
  }
  if (!settings) {
    const { short } = await api.getGeoLanguages();
    settings = { language: short };
  }
  return settings;
};

const initialState: SystemState = {
  light: true,
  bootstrap: false,
  language: "en",
};

export const toggle_light = createAction("toggle light mode");
export const bootstrap = createAction<any>("bootstrap app");

export const select_language = createAction<string>("select lang");

const reducer = createReducer(initialState, {
  [toggle_light.type]: (state, _) => ({
    ...state,
    light: !state.light,
  }),
  [bootstrap.type]: (state, action) => ({
    ...state,
    ...action.payload,
    bootstrap: true,
  }),
  [select_language.type]: (state, action) => ({
    ...state,
    language: action.payload,
  }),
});

const initializeStore = (preloadedState = initialState) => {
  return configureStore({
    reducer,
    preloadedState,
  });
};
export const store = initializeStore();

store.subscribe(() => {
  localStorage.setItem("applicationState", JSON.stringify(store.getState()));
});

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
