export class Cache {
  private cache;
  constructor() {
    this.cache = new Map();
  }

  has(key) {
    return this.cache.has(key);
  }

  set(key, value) {
    return this.cache.set(key, [value, Date.now()]);
  }

  get(key) {
    return this.cache.get(key)[0];
  }

  delete(key) {
    return this.cache.delete(key);
  }

  clear() {
    return this.cache.clear();
  }
  isExpired(key, seconds) {
    const [, timestamp] = this.cache.get(key);

    return (Date.now() - timestamp) / 1000 > seconds;
  }
}

const cache = new Cache();


export default cache;
