import styled from "@emotion/styled";

export const PageContainer = styled.div`
  display: flex;  
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: start;
  min-height: 100vh;
`;

interface Props {
  children: React.ReactNode;
}
export default function MainLayout(props: Props) {
  return <PageContainer>{props.children}</PageContainer>;
}
